import PageWrapper from 'common/components/PageWrapper'
import UserForm from 'common/components/UserForm'
import { selectedUserInfoState } from 'modules/user/stores/atom'
import { useTranslation } from 'react-i18next'
import { useRecoilValue } from 'recoil'

import Box from '@mui/material/Box'

export enum AccountTab {
  General = 'general',
  Password = 'password',
}

const ProfilePage = () => {
  const { t } = useTranslation('account')

  const userData = useRecoilValue(selectedUserInfoState)

  const LINKS = [
    {
      name: t('account'),
    },
  ]
  
  return (
    <PageWrapper titleHead={t('account')} title={t('account')} links={LINKS}>
      <Box sx={{ width: '100%', typography: 'body1', userSelect: 'none' }}>
        <UserForm prefilledData={userData} hidePermission />
      </Box>
    </PageWrapper>
  )
}

export default ProfilePage
