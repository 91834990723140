import PageWrapper from 'common/components/PageWrapper'
import { useMutateCreateFeedback } from 'modules/user/api/mutation'
import { enqueueSnackbar } from 'notistack'
import { Controller, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { Box, Button, Stack, TextField, Typography } from '@mui/material'
import Rating from '@mui/material/Rating'

interface FormDataType {
  rating: number
  comment: string
}

const FeedbackPage = () => {
  const { t } = useTranslation('feedback')
  const submitFeedbackMutation = useMutateCreateFeedback()

  const LINKS = [
    {
      name: t('feedback.description'),
    },
  ]

  const { control, handleSubmit, reset, watch } = useForm({
    defaultValues: {
      rating: 0,
      comment: '',
    },
  })

  const onSubmit = async (data: FormDataType) => {
    let ratingInt = 0
    if (typeof data.rating === 'string') {
      ratingInt = parseInt(data.rating)
    }
    
    await submitFeedbackMutation
      .mutateAsync(
        {
          rating: ratingInt,
          comments: data.comment,
          source: 'website',
        },
        {
          onSuccess: () => {
            enqueueSnackbar(t('submit.success'), { variant: 'success' })
          },
        }
      )
      .finally(() => reset((_v) => ({ rating: 0, comment: '' })))
  }

  return (
    <PageWrapper titleHead={t('feedback')} title={t('feedback')} links={LINKS}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Stack alignItems="center" spacing={2}>
          <Box width="124px" height="124px">
            <img src="/static/feedback/comment.svg" alt="comment" />
          </Box>
          <Typography variant="h5">{t('give.star')}</Typography>
          <Controller
            name="rating"
            control={control}
            render={({ field }) => <Rating {...field} size="large" />}
          />
          <Controller
            name="comment"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                fullWidth
                multiline
                minRows={5}
                placeholder={t('give.feedback')}
                label={t('give.feedback')}
              />
            )}
          />
          <Button fullWidth variant="contained" type="submit" disabled={watch('rating') === 0}>
            {t('submit.feedback')}
          </Button>
        </Stack>
      </form>
    </PageWrapper>
  )
}

export default FeedbackPage
