import PageWrapper from 'common/components/PageWrapper'
import { useTranslation } from 'react-i18next'

import ChangePhoneForm from './ChangePhoneForm'

const ChangePhonePage = () => {
  const { t } = useTranslation('changephone')
  const LINKS = [
    {
      name: t('phone'),
    },
  ]

  return (
    <PageWrapper titleHead={t('change.phone')} title={t('change.phone')} links={LINKS}>
      <ChangePhoneForm />
    </PageWrapper>
  )
}

export default ChangePhonePage
