import { useQuery } from '@tanstack/react-query'
import { USER_INFO_KEY } from 'common/constants/queryKeys'

import { getUserInfo } from '.'

export const useUserInfo = () => {
  return useQuery({
    queryKey: USER_INFO_KEY,
    queryFn: async () => {
      const { data } = await getUserInfo()
      return data.data
    },
    staleTime: Infinity,
  })
}
