import { selectedUserInfoState } from 'modules/user/stores/atom'
import { useRecoilValue } from 'recoil'
import palette from 'theme/palette'

import createAvatar from 'utils/createAvatar'

import { MAvatar } from './@material-extend'
import { MAvatarProps } from './@material-extend/MAvatar'

export default function MyAvatar({ ...other }: MAvatarProps) {
  const data = useRecoilValue(selectedUserInfoState)

  return (
    <MAvatar
      src={data?.pictureURL ?? ''}
      alt={data?.name ?? 'user-avatar'}
      color="success" 
      backgroundColor={palette.dark.gradients.primary}
      {...other}
    >
      {createAvatar(data?.name ?? '').name}
    </MAvatar>
  )
}
