import { useMutation } from '@tanstack/react-query'
import { setLocalStorage } from 'common/utils/localstorage'
import { Role } from 'modules/user/constants'
import { useSnackbar } from 'notistack'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { PATH_DASHBOARD } from 'routes/paths'

import {
  resetPassword,
  login,
  signUp,
  requestResetPasswordOTP,
  uploadPhoto,
  logout,
} from '.'
import { ACCESS_TOKEN_KEY } from '../constants'
import { decodeAccessToken } from '../utils/jwt'
import { SendOTPApi, VerifyOTPApi } from './types'

export const useMutateLogin = () => {
  const navigate = useNavigate()

  return useMutation(login, {
    onSuccess: (res) => {
      const { data, status } = res.data
      if (status === 200) {
        const accessToken = data.accessToken
        const decoded = decodeAccessToken(accessToken)
        setLocalStorage(ACCESS_TOKEN_KEY, accessToken)
        switch (decoded.role) {
          case Role.CycleCaretaker:
            navigate(PATH_DASHBOARD.ponds.root)
            break
          default:
            navigate(PATH_DASHBOARD.farms.root)
            break
        }
      }
    },
  })
}

export const useMutateSendOTP = (fn: SendOTPApi) => {
  const { t } = useTranslation(['auth'])
  const { enqueueSnackbar } = useSnackbar()

  return useMutation(fn, {
    onSuccess: (_) => {
      enqueueSnackbar(t('auth:send.otp.success'), {
        variant: 'success',
      })
    },
  })
}

export const useMutateVerifyOTP = (fn: VerifyOTPApi) => {
  return useMutation(fn)
}

export const useMutateSignUp = () => {
  const { enqueueSnackbar } = useSnackbar()
  const navigate = useNavigate()

  return useMutation(signUp, {
    onSuccess: (res) => {
      const { data } = res.data
      const accessToken = data.accessToken
      if (accessToken) {
        setLocalStorage(ACCESS_TOKEN_KEY, data.accessToken)
        const user = decodeAccessToken(accessToken)
        switch (user.role) {
          case Role.CycleCaretaker:
            navigate(PATH_DASHBOARD.ponds.root)
            break
          default:
            navigate(PATH_DASHBOARD.farms.root)
            break
        }
      }
      enqueueSnackbar('สมัครสำเร็จ', { variant: 'success' })
    },
  })
}

export const useMutateResetPassword = () => {
  const { enqueueSnackbar } = useSnackbar()

  return useMutation(resetPassword, {
    onSuccess: (_) => {
      enqueueSnackbar('เปลี่ยนรหัสสำเร็จ', { variant: 'success' })
    },
  })
}

export const useMutateRequestResetPasswordOTP = () => {
  const { enqueueSnackbar } = useSnackbar()

  return useMutation(requestResetPasswordOTP, {
    onSuccess: (_) => {
      enqueueSnackbar('ส่ง OTP แล้ว', { variant: 'success' })
    },
  })
}

export const useMutateUploadPhoto = () => {
  return useMutation(uploadPhoto)
}

export const useMutateLogout = () => {
  return useMutation(logout)
}
