import PageWrapper from 'common/components/PageWrapper'
import { useTranslation } from 'react-i18next'
import ResetPasswordForm from './ResetPasswordForm'

const ResetPasswordPage = () => {
  const { t } = useTranslation('resetpassword')
  const LINKS = [
    {
      name: t('reset.password'),
    },
  ]

  return (
    <PageWrapper titleHead={t('reset.password')} title={t('reset.password')} links={LINKS}>
      <ResetPasswordForm />
    </PageWrapper>
  )
}

export default ResetPasswordPage
