import useAuthenticated, { accessToken } from 'common/hooks/useAuthenticated'
import useHasMounted from 'common/hooks/useHasMounted'
import { decodeAccessToken } from 'modules/auth/utils/jwt'
import { Role } from 'modules/user/constants'
import { useState, ReactNode, useEffect } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import { PATH_AUTH, PATH_DASHBOARD } from 'routes/paths'

type AuthGuardProps = {
  children: ReactNode
}

export default function AuthGuard({ children }: AuthGuardProps) {
  const authenticated = useAuthenticated()
  const hasMounted = useHasMounted()
  const [requestedLocation, setRequestedLocation] = useState<string | null>(null)
  const { pathname } = useLocation()
  const navigate = useNavigate()

  useEffect(() => {
    if (!authenticated) {
      if (pathname !== requestedLocation) {
        setRequestedLocation(pathname)
      }
      navigate(PATH_AUTH.login)
      return
    }

    if (pathname === PATH_DASHBOARD.root) {
      const decoded = decodeAccessToken(accessToken)
      switch (decoded.role) {
        case Role.PrimaryOwner:
          navigate(PATH_DASHBOARD.ponds.root)
          break
        default:
          navigate(PATH_DASHBOARD.farms.root)
      }
    }
  }, [authenticated])

  if (!hasMounted || !authenticated) {
    return null
  }

  return <>{children}</>
}
