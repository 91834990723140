import { useCallback } from 'react'
import * as yup from 'yup'

const useYupValidationResolver = <T extends yup.AnyObject>(validationSchema: yup.ObjectSchema<T>) =>
  useCallback(
    async (data) => {
      try {
        const values = await validationSchema.validate(data, {
          abortEarly: false,
        })

        return {
          values,
          errors: {},
        }
      } catch (errors) {
        return {
          values: {},
          errors: (errors as yup.ValidationError).inner.reduce(
            (allErrors: Record<string, any>, currentError: yup.ValidationError) => {
              const path = currentError.path ? currentError.path.toString() : 'undefined'

              return {
                ...allErrors,
                [path]: {
                  type: currentError.type ?? 'validation',
                  message: currentError.message,
                },
              }
            },
            {}
          ),
        }
      }
    },
    [validationSchema]
  )

export default useYupValidationResolver
