import OTPVerifyForm from 'common/components/OTPVerifyForm'
import useYupValidationResolver from 'common/hooks/useYupValidationResolver'
import { requestResetPasswordOTP, verifyResetPasswordOTP } from 'modules/auth/api'
import { useMutateRequestResetPasswordOTP, useMutateResetPassword } from 'modules/auth/api/mutation'
import { useUserInfo } from 'modules/user/api/queries'
import { useState } from 'react'
import { Controller, SubmitHandler, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { PATH_DASHBOARD } from 'routes/paths'
import * as Yup from 'yup'

import LoadingButton from '@mui/lab/LoadingButton'
import { Box, IconButton, InputAdornment, Paper, Stack, TextField } from '@mui/material'

import eyeFill from '@iconify/icons-eva/eye-fill'
import eyeOffFill from '@iconify/icons-eva/eye-off-fill'
import { Icon } from '@iconify/react'

interface FormDataType {
  password: string
  confirmPassword: string
}

const ResetPasswordForm = () => {
  const { t } = useTranslation('resetpassword')
  const [loading, setLoading] = useState(false)
  const [showPassword, setShowPassword] = useState(false)
  const [activeStep, setActiveStep] = useState(0)
  const navigate = useNavigate()

  const { data: user } = useUserInfo()

  const sendOTP = useMutateRequestResetPasswordOTP()
  const resetPassword = useMutateResetPassword()

  const schema = Yup.object().shape({
    password: Yup.string().required(t('require.password')),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref('password'), undefined], t('unmatched.password'))
      .required(),
  })

  const resolver = useYupValidationResolver<FormDataType>(schema)

  const {
    control,
    handleSubmit,
    formState: { errors },
    watch,
  } = useForm<FormDataType>({
    defaultValues: {
      password: '',
      confirmPassword: '',
    },
    resolver,
  })

  const onSubmit: SubmitHandler<FormDataType> = async (_data) => {
    setLoading(true)
    await sendOTP
      .mutateAsync(
        { phone: user?.phone ?? '' },
        {
          onSuccess: () => {
            setActiveStep(1)
          },
        }
      )
      .finally(() => setLoading(false))
  }

  switch (activeStep) {
    case 0:
      return (
        <form id="reset-password-form" onSubmit={handleSubmit(onSubmit)}>
          <Paper sx={{ p: 3, my: 3, minHeight: 120 }}>
            <Stack spacing={3}>
              <Controller
                name="password"
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    fullWidth
                    type="password"
                    label={t('new.password')}
                    error={!!errors.password}
                    helperText={errors.password ? errors.password.message : undefined}
                  />
                )}
              />
              <Controller
                name="confirmPassword"
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    fullWidth
                    type={showPassword ? 'text' : 'password'}
                    label={t('confirm.password')}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                            <Icon icon={showPassword ? eyeFill : eyeOffFill} />
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                    error={!!errors.confirmPassword}
                    helperText={errors.confirmPassword ? errors.confirmPassword.message : undefined}
                  />
                )}
              />
            </Stack>
          </Paper>
          <Box sx={{ my: 2 }}>
            <LoadingButton
              fullWidth
              size="large"
              type="submit"
              variant="contained"
              loading={loading}
            >
              {t('reset.password')}
            </LoadingButton>
          </Box>
        </form>
      )
    case 1:
      return (
        <OTPVerifyForm
          phoneNumber={user?.phone ?? ''}
          verifyApi={verifyResetPasswordOTP}
          sendOTPApi={requestResetPasswordOTP}
          onSuccess={async (res) => {
            const { data } = res.data as { data: { refCode: string } }
            if (data.refCode) {
              await resetPassword.mutateAsync(
                {
                  password: watch('confirmPassword'),
                  refCode: data.refCode,
                },
                {
                  onSuccess: () => {
                    navigate(PATH_DASHBOARD.general.profile)
                  },
                }
              )
            }
          }}
        />
      )
    default:
      return <div />
  }
}

export default ResetPasswordForm
