import {
  currentForgetPasswordStepState,
  currentRegisterStepState,
  forgetPasswordPhoneState,
  forgetPasswordStepState,
  otpRefCodeState,
  phoneNumberState,
  registerStepState,
} from 'modules/auth/stores/atom'
import { employeeTableFilterState } from 'modules/employee/stores/atom'
import { selectedFarmState } from 'modules/farm/stores/atom'
import {
  selectedMealDateState,
  selectedTableDateState,
  selectedTableFeedingTypeState,
} from 'modules/food/stores/atom'
import { selectedCycleIdState, selectedPondState } from 'modules/pond/stores/atom'
import { selectedUserInfoState } from 'modules/user/stores/atom'
import { useResetRecoilState } from 'recoil'

const useClearRecoilState = () => {
  // auth
  const resetCurrentRegisterStepState = useResetRecoilState(currentRegisterStepState)
  const resetRegisterStepState = useResetRecoilState(registerStepState)
  const resetPhoneNumberState = useResetRecoilState(phoneNumberState)
  const resetCurrentForgetPasswordStepState = useResetRecoilState(currentForgetPasswordStepState)
  const resetForgetPasswordStepState = useResetRecoilState(forgetPasswordStepState)
  const resetForgetPasswordPhoneState = useResetRecoilState(forgetPasswordPhoneState)
  const resetOtpRefCodeState = useResetRecoilState(otpRefCodeState)

  // employee
  const resetEmployeeTableFilterState = useResetRecoilState(employeeTableFilterState)

  // farm
  const resetSelectedFarmState = useResetRecoilState(selectedFarmState)

  // food
  const resetSelectedMealDateState = useResetRecoilState(selectedMealDateState)
  const resetSelectedTableDateState = useResetRecoilState(selectedTableDateState)
  const resetSelectedTableFeedingTypeState = useResetRecoilState(selectedTableFeedingTypeState)

  // pond
  const resetSelectedCycleIdState = useResetRecoilState(selectedCycleIdState)
  const resetSelectedPondState = useResetRecoilState(selectedPondState)

  // user
  const resetSelectedUserInfoState = useResetRecoilState(selectedUserInfoState)

  const clear = () => {
    resetCurrentRegisterStepState()
    resetRegisterStepState()
    resetPhoneNumberState()
    resetCurrentForgetPasswordStepState()
    resetForgetPasswordStepState()
    resetForgetPasswordPhoneState()
    resetOtpRefCodeState()
    resetEmployeeTableFilterState()
    resetSelectedFarmState()
    resetSelectedMealDateState()
    resetSelectedTableDateState()
    resetSelectedTableFeedingTypeState()
    resetSelectedCycleIdState()
    resetSelectedPondState()
    resetSelectedUserInfoState()
  }

  return clear
}

export default useClearRecoilState
