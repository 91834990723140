import { getLocalStorage } from 'common/utils/localstorage'
import isEmpty from 'lodash/isEmpty'
import { ACCESS_TOKEN_KEY } from 'modules/auth/constants'
import { useEffect, useState } from 'react'

export const accessToken = getLocalStorage(ACCESS_TOKEN_KEY)

export default function useAuthenticated() {
  const accessToken = getLocalStorage(ACCESS_TOKEN_KEY)
  const [authenticated, setAuthenticated] = useState(!isEmpty(accessToken))

  useEffect(() => {
    if (accessToken) {
      setAuthenticated(true)
    }
  }, [accessToken])

  return authenticated
}
