import { AxiosError, AxiosResponse } from 'axios'
import useYupValidationResolver from 'common/hooks/useYupValidationResolver'
import { useMutateSendOTP, useMutateVerifyOTP } from 'modules/auth/api/mutation'
import { SendOTPApi, VerifyOTPApi } from 'modules/auth/api/types'
import { memo, useState } from 'react'
import { Controller, SubmitHandler, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import ReactInputVerificationCode from 'react-input-verification-code'
import * as Yup from 'yup'

import LoadingButton from '@mui/lab/LoadingButton'
import Box from '@mui/material/Box'
import Container from '@mui/material/Container'
import FormHelperText from '@mui/material/FormHelperText'
import Link from '@mui/material/Link'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'

import './styles.css'

interface FormDataType {
  otp: string
}

interface OTPVerifyFormProps {
  phoneNumber: string
  verifyApi: VerifyOTPApi
  sendOTPApi: SendOTPApi
  onSuccess: (res: AxiosResponse<unknown>) => void
}

const OTPVerifyForm = ({ phoneNumber, verifyApi, sendOTPApi, onSuccess }: OTPVerifyFormProps) => {
  const { t } = useTranslation(['auth'])
  const [loading, setLoading] = useState(false)

  const verifyOTPMutation = useMutateVerifyOTP(verifyApi)
  const sendOTPMutation = useMutateSendOTP(sendOTPApi)

  const schema = Yup.object().shape({
    otp: Yup.string()
      .test('length', t('require.otp'), (val) => val?.length === 6)
      .required(),
  })

  const resolver = useYupValidationResolver<FormDataType>(schema)

  const {
    control,
    handleSubmit,
    formState: { errors },
    setError,
  } = useForm<FormDataType>({
    defaultValues: {
      otp: '',
    },
    resolver,
  })

  const onSubmit: SubmitHandler<FormDataType> = async (data) => {
    setLoading(true)
    await verifyOTPMutation
      .mutateAsync(
        {
          phone: phoneNumber,
          code: data.otp,
        },
        {
          onSuccess,
          onError: (err) => {
            const { response } = err as AxiosError

            if (response?.status === 409) {
              setError('otp', {
                type: 'server',
                message: t('invalid.otp'),
              })
            }
          },
        }
      )
      .finally(() => {
        setLoading(false)
      })
  }

  const sendOTP = async () => {
    await sendOTPMutation.mutateAsync({
      phone: phoneNumber,
    })
  }

  return (
    <form id="verify-code" onSubmit={handleSubmit(onSubmit)}>
      <Container>
        <Box sx={{ mt: 3 }}>
          <Typography variant="h4" paragraph>
            {t('check.sms.mobile')}
          </Typography>
          <Typography sx={{ color: 'text.secondary' }}>
            {t('check.sms.mobile.helper', { phone: phoneNumber })}
          </Typography>
        </Box>
        <Box
          sx={{
            mt: 5,
            mb: 3,
          }}
        >
          <div className="custom-styles">
            <Controller
              name="otp"
              control={control}
              render={({ field }) => (
                <ReactInputVerificationCode {...field} length={6} placeholder="" />
              )}
            />
          </div>
          <Box sx={{ height: '20px' }}>
            <FormHelperText error={!!errors.otp} style={{ textAlign: 'right' }}>
              {errors?.otp?.message}
            </FormHelperText>
          </Box>
          <Stack direction="row" spacing={1} useFlexGap justifyContent="center">
            <Typography variant="body2" align="center">
              {t('unreceived.otp')}
            </Typography>
            <Link
              variant="body2"
              component="button"
              type="button"
              underline="none"
              onClick={sendOTP}
            >
              {t('resend.otp')}
            </Link>
          </Stack>
        </Box>
      </Container>
      <Box sx={{ my: 2 }}>
        <LoadingButton fullWidth size="large" type="submit" variant="contained" loading={loading}>
          {t('confirm')}
        </LoadingButton>
      </Box>
    </form>
  )
}

export default memo(OTPVerifyForm)
