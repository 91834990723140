import useMedia from 'common/hooks/useMedia'
import { Trans, useTranslation } from 'react-i18next'

import { Box, Stack, Typography, useTheme } from '@mui/material'

import { Icon } from '@iconify/react'

interface FeatureCardProps {
  titleI18nKey: string
  icon: string
  descriptionI18nKey: string
}

const FeatureCard = ({ titleI18nKey, icon, descriptionI18nKey }: FeatureCardProps) => {
  const theme = useTheme()
  const { t } = useTranslation('landing')
  const isMobile = useMedia('md')

  return (
    <Stack mb={4} alignItems={isMobile ? 'start' : 'center'}>
      <Stack direction="row" alignItems="center" spacing={1}>
        <Box>
          <Icon
            icon={icon}
            color={theme.palette.primary.main}
            style={{ width: isMobile ? '16px' : '24px', height: isMobile ? '16px' : '24px' }}
          />
        </Box>
        <Typography
          variant="h6"
          fontSize={isMobile ? '14px' : '16px'}
          color={theme.palette.primary.main}
          gutterBottom
        >
          {t(titleI18nKey)}
        </Typography>
      </Stack>
      <Typography
        variant="body1"
        fontSize={isMobile ? '12px' : '16px'}
        color="textSecondary"
        textAlign={isMobile ? 'left' : 'center'}
      >
        <Trans i18nKey={`landing:${descriptionI18nKey}`} />
      </Typography>
    </Stack>
  )
}

export default FeatureCard
