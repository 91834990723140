import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import NotistackProvider from 'common/components/NotistackProvider'
import { BaseOptionChartStyle } from 'common/components/charts/BaseOptionChart'
import { th } from 'date-fns/locale'
import { RecoilRoot } from 'recoil'
// routes
import Router from 'routes'
// theme
import ThemeConfig from 'theme'
import GlobalStyles from 'theme/globalStyles'

import { LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'

import GoogleAnalytics from 'components/GoogleAnalytics'
import { ProgressBarStyle } from 'components/LoadingScreen'
import ScrollToTop from 'components/ScrollToTop'
import ThemeLocalization from 'components/ThemeLocalization'
import ThemePrimaryColor from 'components/ThemePrimaryColor'

export const queryClient = new QueryClient()

export default function App() {
  return (
    <ThemeConfig>
      <ThemePrimaryColor>
        <ThemeLocalization>
          <QueryClientProvider client={queryClient}>
            <RecoilRoot>
              <NotistackProvider>
                <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={th}>
                  <GlobalStyles />
                  <ProgressBarStyle />
                  <BaseOptionChartStyle />
                  <ScrollToTop />
                  <GoogleAnalytics />
                  <Router />
                </LocalizationProvider>
              </NotistackProvider>
            </RecoilRoot>
          </QueryClientProvider>
        </ThemeLocalization>
      </ThemePrimaryColor>
    </ThemeConfig>
  )
}
