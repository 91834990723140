import { atom } from 'recoil'

export const currentRegisterStepState = atom<number>({
  key: 'currentRegisterStepState',
  default: 0,
})

export const registerStepState = atom<string[]>({
  key: 'registerStepState',
  default: [],
})

export const phoneNumberState = atom<string>({
  key: 'phoneNumberState',
  default: '',
})

export const currentForgetPasswordStepState = atom<number>({
  key: 'currentForgetPasswordStepState',
  default: 0,
})

export const forgetPasswordStepState = atom<string[]>({
  key: 'forgetPasswordStepState',
  default: [],
})

export const forgetPasswordPhoneState = atom<string>({
  key: 'forgetPasswordPhoneState',
  default: ''
})

export const otpRefCodeState = atom<string>({
  key: 'otpRefCodeState',
  default: ''
})