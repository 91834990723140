import { useMutation } from '@tanstack/react-query'

import {
  changePhoneNumber,
  createFeedback,
  editUserInfo,
  sendOTPChangePhone,
  uploadProfilePicture,
  verifyOTPChangePhone,
} from '.'

export const useMutatePatchUserInfo = () => {
  return useMutation(editUserInfo)
}

export const useMutateUploadProfilePicture = () => {
  return useMutation(uploadProfilePicture)
}

export const useMutateCreateFeedback = () => {
  return useMutation(createFeedback)
}

export const useMutateSendOTPChangePhone = () => {
  return useMutation(sendOTPChangePhone)
}

export const useMutateVerifyOTPChangePhone = () => {
  return useMutation(verifyOTPChangePhone)
}

export const useMutateChangePhoneNumber = () => {
  return useMutation(changePhoneNumber)
}