import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit'
import { useDispatch as useReduxDispatch, useSelector as useReduxSelector } from 'react-redux'
import logger from 'redux-logger'
import { persistStore, persistReducer } from 'redux-persist'
import thunk from 'redux-thunk'

import { rootPersistConfig, rootReducer } from './rootReducer'

// ----------------------------------------------------------------------

const middlewares = []
middlewares.push(thunk)

if (process.env.NODE_ENV === 'development') {
  middlewares.push(logger)
}

const store = configureStore({
  reducer: persistReducer(rootPersistConfig, rootReducer),
  middleware: getDefaultMiddleware({
    serializableCheck: false,
    immutableCheck: false,
  }).concat(middlewares),
})

const persistor = persistStore(store)

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch

const { dispatch } = store

const useSelector = useReduxSelector
const useDispatch = () => useReduxDispatch<AppDispatch>()

export { store, persistor, dispatch, useSelector, useDispatch }
