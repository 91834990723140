import { CycleHistory, PondResponse } from 'modules/pond/api/types'
import { atom } from 'recoil'

export const selectedPondState = atom<PondResponse | null>({
    key: 'selectedPondState',
    default: null,
})

export const selectedCycleIdState = atom<string | null>({
    key: 'selectedCycleIdState',
    default: null
})

export const currentCycleInfoState = atom<CycleHistory | null>({
    key: 'currentCycleInfoState',
    default: null
})