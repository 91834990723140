import useAuthenticated from 'common/hooks/useAuthenticated'
import { ReactNode } from 'react'
import { Navigate } from 'react-router-dom'
import { PATH_DASHBOARD } from 'routes/paths'

type GuestGuardProps = {
  children: ReactNode
}

export default function GuestGuard({ children }: GuestGuardProps) {
  const authenticated = useAuthenticated()

  if (authenticated) {
    return <Navigate to={PATH_DASHBOARD.root} />
  }

  return <>{children}</>
}
