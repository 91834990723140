import { BaseResponse, MessageResponse } from 'common/types/api'
import axios from 'core/api/axios'

import {
  ResetPasswordBody,
  LoginBody,
  LoginResponse,
  SendOTPBody,
  SignUpBody,
  VerifyOTPBody,
  UploadPhotoResponse,
  VerifyOTPResponse,
  SignUpResponse,
} from './types'

export const login = (body: LoginBody) =>
  axios.post<BaseResponse<LoginResponse>>('/v2/admin/signin', body, { withCredentials: true })

export const sendOTP = (body: SendOTPBody) =>
  axios.post<MessageResponse>('/v2/admin/signup/send_sms_otp', body)

export const verifyOTP = (body: VerifyOTPBody) =>
  axios.post<BaseResponse<VerifyOTPResponse>>('/v2/admin/signup/verify_sms_otp', body)

export const signUp = (body: SignUpBody) =>
  axios.post<BaseResponse<SignUpResponse>>('/v2/admin/signup', body, { withCredentials: true })

export const resetPassword = (body: ResetPasswordBody) =>
  axios.post('/v2/admin/reset_password', body)

export const requestResetPasswordOTP = (body: SendOTPBody) =>
  axios.post<MessageResponse>('/v2/admin/reset_password/send_sms_otp', body)

export const verifyResetPasswordOTP = (body: VerifyOTPBody) =>
  axios.post<BaseResponse<VerifyOTPResponse>>('/v2/admin/reset_password/verify_sms_otp', body)

export const uploadPhoto = (data: FormData) =>
  axios.post<BaseResponse<UploadPhotoResponse>>('/auth/signup/upload_photo', data, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  })

export const renewAccessToken = () =>
  axios.get<BaseResponse<LoginResponse>>('/v2/admin/renew/access_token', { withCredentials: true })

export const logout = (accessToken: string) =>
  axios.post<MessageResponse>('/v2/admin/signout', { accessToken })
