import axios from 'axios'
import { snackbarProviderRef } from 'common/components/NotistackProvider'
import { getLocalStorage, setLocalStorage } from 'common/utils/localstorage'
import { API_BASE_URL } from 'env'
import { renewAccessToken } from 'modules/auth/api'
import { ACCESS_TOKEN_KEY } from 'modules/auth/constants'

let retryCount = 0
const MAX_RETRIES = 5

const axiosInstance = axios.create({
  baseURL: API_BASE_URL,
  headers: {
    'Content-Type': 'application/json',
  },
})

axiosInstance.interceptors.request.use((config) => {
  const token = getLocalStorage(ACCESS_TOKEN_KEY)

  let clonedConfig = { ...config }
  if (token) {
    clonedConfig.headers.Authorization = `Bearer ${token}`
  }

  return {
    ...clonedConfig,
    cancelToken: axios.CancelToken.source().token,
  }
})

axiosInstance.interceptors.response.use(
  (res) => res,
  async (err) => {
    const { response, config } = err
    if (response) {
      const status = response.status

      if (status === 401 && !config._retry && retryCount < MAX_RETRIES) {
        config._retry = true
        retryCount++
        try {
          await new Promise((resolve) => setTimeout(resolve, 2000))

          const response = await renewAccessToken()
          const {
            data: { accessToken },
          } = response.data
          setLocalStorage(ACCESS_TOKEN_KEY, accessToken)
          config.headers.Authorization = `Bearer ${accessToken}`
          retryCount = 0
          return axiosInstance(config)
        } catch (err) {
          localStorage.clear()
          window.location.replace('/auth/login')
          throw err
        }
      }

      const skipStatuses = [400, 409]
      if (!skipStatuses.includes(status)) {
        const errorMessage = 'เกิดข้อผิดพลาด กรุณาลองใหม่อีกครั้ง'
        snackbarProviderRef.current?.enqueueSnackbar(errorMessage, {
          variant: 'error',
        })
      }
    }

    return Promise.reject(err)
  }
)

export default axiosInstance
