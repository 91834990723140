import useMedia from 'common/hooks/useMedia'
import { selectedUserInfoState } from 'modules/user/stores/atom'
import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import { useRecoilValue } from 'recoil'

// routes
import { Box, Drawer, Toolbar, Typography, useTheme } from '@mui/material'

import { MHidden } from 'components/@material-extend'
import MyAvatar from 'components/MyAvatar'
// components
import NavSection from 'components/NavSection'
import Scrollbar from 'components/Scrollbar'

import sidebarConfig from './SidebarConfig'

// ----------------------------------------------------------------------

const DRAWER_WIDTH_DESKTOP = 250
const DRAWER_WIDTH_MOBILE = '100%'

// ----------------------------------------------------------------------

type DashboardSidebarProps = {
  isOpenSidebar: boolean
  onCloseSidebar: VoidFunction
}

export default function DashboardSidebar({ isOpenSidebar, onCloseSidebar }: DashboardSidebarProps) {
  const { pathname } = useLocation()
  const theme = useTheme()
  const userInfo = useRecoilValue(selectedUserInfoState)
  const isMobile = useMedia('sm')

  useEffect(() => {
    if (isOpenSidebar) {
      onCloseSidebar()
    }
  }, [pathname])

  const renderContent = (
    <Scrollbar pt={3}>
      <Toolbar />
      <MHidden width="smUp">
        <Box
          display="flex"
          borderRadius={1.5}
          p={1.5}
          mx={2}
          bgcolor={theme.palette.grey[200]}
          alignItems="center"
        >
          <MyAvatar />
          <Box ml={2}>
            <Typography fontWeight="bold" fontSize={14} color={theme.palette.grey[900]}>
              {userInfo?.name}
            </Typography>
          </Box>
        </Box>
      </MHidden>
      <NavSection navConfig={sidebarConfig(userInfo?.roleId)} />
      <Box flexGrow={1} />
    </Scrollbar>
  )

  return (
    <Box>
      <Drawer
        anchor={isMobile ? 'top' : 'left'}
        open={isOpenSidebar}
        onClose={onCloseSidebar}
        sx={{ [`.MuiBackdrop-root`]: { background: 'none' } }}
        PaperProps={{
          sx: {
            width: isMobile ? DRAWER_WIDTH_MOBILE : DRAWER_WIDTH_DESKTOP,
            boxShadow: 0,
            height: '100%',
          },
        }}
      >
        {renderContent}
      </Drawer>
    </Box>
  )
}
