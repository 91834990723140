import { styled, Theme } from '@mui/material/styles'

const BlurCircle = styled('div')(({ theme, $isMobile }: { theme?: Theme; $isMobile: boolean }) => ({
  position: 'absolute',
  top: $isMobile ? '70%' : '50%',
  right: $isMobile ? 0 : '-5%',
  width: $isMobile ? '200px' : '400px',
  height: $isMobile ? '200px' : '400px',
  backgroundColor: theme?.palette.primary.main,
  borderRadius: '50%',
  filter: 'blur(100px)',
  transform: 'translate(-50%, -50%)',
}))

export default BlurCircle
