// material
import { Box, BoxProps } from '@mui/material'

// ----------------------------------------------------------------------

export default function Logo({ sx }: BoxProps) {
  return (
    <Box sx={{ width: '72px', ...sx }}>
      <img src="/static/logo/logo_512x512.svg" alt="DoFarm logo" />
    </Box>
  )
}
