// components
import { LINE_OA } from 'common/constants/contact'
import { useUserInfo } from 'modules/user/api/queries'
import { selectedUserInfoState } from 'modules/user/stores/atom'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { useSetRecoilState } from 'recoil'
import { PATH_DASHBOARD } from 'routes/paths'

import { AppBar, Toolbar, IconButton, useTheme, Box, Stack, Link, Button } from '@mui/material'
// material
import { styled } from '@mui/material/styles'

import menuOutline from '@iconify/icons-eva/menu-outline'
import closeIcon from '@iconify/icons-iconamoon/close'
import { Icon } from '@iconify/react'

import { MHidden } from 'components/@material-extend'
import Logo from 'components/Logo'

import AccountPopover from './AccountPopover'

// import NotificationsPopover from './NotificationsPopover'
// import Searchbar from './Searchbar'

// ----------------------------------------------------------------------

const APPBAR_MOBILE = 56
const APPBAR_DESKTOP = 80

const RootStyle = styled(AppBar)(({ theme }) => ({
  position: 'fixed',
  zIndex: theme.zIndex.drawer + 1,
  boxShadow: 'none',
  backgroundColor: 'white',
  // width: '100%',
  // [theme.breakpoints.up('lg')]: {
  //   width: `calc(100% - ${DRAWER_WIDTH + 1}px)`,
  // },
}))

const ToolbarStyle = styled(Toolbar)(({ theme }) => ({
  minHeight: APPBAR_MOBILE,
  display: 'flex',
  justifyContent: 'space-between',
  [theme.breakpoints.up('lg')]: {
    minHeight: APPBAR_DESKTOP,
    padding: theme.spacing(0, 5),
  },
}))

// ----------------------------------------------------------------------

type DashboardNavbarProps = {
  isOpenSidebar: boolean
  onOpenSidebar: VoidFunction
}

const NavBarLogo = () => {
  const navigate = useNavigate()

  return (
    <IconButton disableRipple onClick={() => navigate(PATH_DASHBOARD.farms.root)} sx={{ p: 0 }}>
      <Logo />
    </IconButton>
  )
}

export default function DashboardNavbar({ isOpenSidebar, onOpenSidebar }: DashboardNavbarProps) {
  const { t } = useTranslation('common')
  const theme = useTheme()
  const setUserInfo = useSetRecoilState(selectedUserInfoState)
  const { data: userInfo } = useUserInfo()

  useEffect(() => {
    setUserInfo(userInfo)
  }, [userInfo])

  return (
    <RootStyle>
      <ToolbarStyle>
        <MHidden width="smDown">
          <Box display="flex">
            <IconButton
              disableRipple
              onClick={onOpenSidebar}
              sx={{ mr: 4, color: theme.palette.grey[400] }}
            >
              <Icon icon={menuOutline} />
            </IconButton>
            <NavBarLogo />
          </Box>
          <Stack direction="row" spacing={1} alignItems="center">
            <Button
              LinkComponent={Link}
              href={LINE_OA}
              startIcon={<Icon icon="hugeicons:bubble-chat-question" width={32} height={32} />}
            >
              {t('report.problem')}
            </Button>
            <AccountPopover />
          </Stack>
        </MHidden>

        <MHidden width="smUp">
          <NavBarLogo />
          <Stack direction="row" alignItems="center">
          <Button
              LinkComponent={Link}
              href={LINE_OA}
              startIcon={<Icon icon="hugeicons:bubble-chat-question" width={32} height={32} />}
            >
              {t('report.problem')}
            </Button>
            <IconButton
              disableRipple
              onClick={onOpenSidebar}
              sx={{ color: theme.palette.grey[400] }}
            >
              <Icon icon={isOpenSidebar ? closeIcon : menuOutline} />
            </IconButton>
          </Stack>
        </MHidden>
      </ToolbarStyle>
    </RootStyle>
  )
}
