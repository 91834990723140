import { Theme } from '@mui/material'

// ----------------------------------------------------------------------

export default function Drawer(theme: Theme) {

  return {
    MuiDrawer: {
      styleOverrides: {
        modal: {
          '&[role="presentation"]': {
            '& .MuiDrawer-paperAnchorLeft': {
              borderRight: '1px #F5F5F5 solid'
            },
            '& .MuiDrawer-paperAnchorRight': {
              borderLeft: '1px #F5F5F5 solid'
            },
          },
        },
      },
    },
  }
}
