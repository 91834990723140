import jwtDecode from 'jwt-decode'
import { Role } from 'modules/user/constants'

export interface DecodedAccessToken {
  role: Role
  user_id: number
  phone: string
  status: string
  exp: number
  iat: number
}

export const decodeAccessToken = (accessToken: string): DecodedAccessToken => {
  return jwtDecode(accessToken)
}
