import { AxiosError } from 'axios'
import OTPVerifyForm from 'common/components/OTPVerifyForm'
import useYupValidationResolver from 'common/hooks/useYupValidationResolver'
import { sendOTPChangePhone, verifyOTPChangePhone } from 'modules/user/api'
import { useMutateChangePhoneNumber, useMutateSendOTPChangePhone } from 'modules/user/api/mutation'
import { useUserInfo } from 'modules/user/api/queries'
import { enqueueSnackbar } from 'notistack'
import { useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { PATH_DASHBOARD } from 'routes/paths'
import * as Yup from 'yup'

import LoadingButton from '@mui/lab/LoadingButton'
import { Box, Paper, Stack, TextField } from '@mui/material'

import { thaiPhoneRegExp } from 'utils/regExp'

interface FormDataType {
  phone: string
}

const ChangePhoneForm = () => {
  const { t } = useTranslation('changephone')
  const [loading, setLoading] = useState(false)
  const [activeStep, setActiveStep] = useState(0)
  const { refetch } = useUserInfo()
  const sendOTP = useMutateSendOTPChangePhone()
  const changePhoneNumber = useMutateChangePhoneNumber()
  const navigate = useNavigate()

  const schema = Yup.object().shape({
    phone: Yup.string().matches(thaiPhoneRegExp, t('invalid.phone')).required(t('require.phone')),
  })

  const resolver = useYupValidationResolver<FormDataType>(schema)

  const {
    control,
    handleSubmit,
    setError,
    watch,
    formState: { errors },
  } = useForm<FormDataType>({
    defaultValues: {
      phone: '',
    },
    resolver,
  })

  const onSubmit = async (data: FormDataType) => {
    setLoading(true)
    await sendOTP
      .mutateAsync(
        { phone: data.phone },
        {
          onSuccess: () => {
            enqueueSnackbar(t('otp.sent'), { variant: 'success' })
            setActiveStep(1)
          },
          onError: (err) => {
            const { response } = err as AxiosError

            if (response?.status === 409) {
              setError('phone', {
                type: 'server',
                message: t('already.registered'),
              })
            }
          },
        }
      )
      .finally(() => {
        setLoading(false)
      })
  }

  switch (activeStep) {
    case 0:
      return (
        <>
          <Stack direction="row" alignItems="center" sx={{ mb: 4 }}></Stack>
          <form id="reset-password-form" onSubmit={handleSubmit(onSubmit)}>
            <Paper sx={{ minHeight: 120 }}>
              <Stack spacing={3}>
                <Controller
                  name="phone"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      fullWidth
                      type="text"
                      label={t('user.phone')}
                      placeholder={t('user.phone.placeholder')}
                      error={!!errors.phone}
                      helperText={errors.phone ? errors.phone.message : undefined}
                    />
                  )}
                />
                <Box sx={{ my: 2 }}>
                  <LoadingButton
                    fullWidth
                    size="large"
                    type="submit"
                    variant="contained"
                    loading={loading}
                  >
                    {t('change.phone.number')}
                  </LoadingButton>
                </Box>
              </Stack>
            </Paper>
          </form>
        </>
      )
    case 1:
      return (
        <OTPVerifyForm
          phoneNumber={watch('phone')}
          verifyApi={verifyOTPChangePhone}
          sendOTPApi={sendOTPChangePhone}
          onSuccess={async (res) => {
            const { data } = res.data as { data: string }
            if (data) {
              await changePhoneNumber.mutateAsync(
                {
                  phone: watch('phone'),
                  refCode: data,
                },
                {
                  onSuccess: () => {
                    enqueueSnackbar(t('change.phone.number.success'), { variant: 'success' })
                    refetch()
                    navigate(PATH_DASHBOARD.general.profile)
                  },
                }
              )
            }
          }}
        />
      )
    default:
      return <div />
  }
}

export default ChangePhoneForm
