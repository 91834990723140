import { EmployeeTableFilter } from 'modules/employee/api/types'
import { atom } from 'recoil'

export const DEFAULT_EMPLOYEE_TABLE_FILTER = {
  name: '',
  // farm: '',
  // responsibility: '',
  // role: '',
}

export const employeeTableFilterState = atom<EmployeeTableFilter>({
  key: 'employeeTableFilterState',
  default: DEFAULT_EMPLOYEE_TABLE_FILTER,
})
