export enum Status {
  Active = 'ACTIVE',
  Deleted = 'DELETED',
}

export enum Role {
  Admin = 'admin',
  PrimaryOwner = 'primary_owner',
  SecondaryOwner = 'secondary_owner',
  FarmManager = 'farm_manager',
  CycleCaretaker = 'cycle_caretaker',
  Finance = 'finance',
  HumanResource = 'human_resource',
}

export enum Gender {
  Male = 'male',
  Female = 'female',
}
