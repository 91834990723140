import { BaseResponse, MessageResponse } from 'common/types/api'
import axios from 'core/api/axios'
import { SendOTPBody, VerifyOTPBody, VerifyOTPResponse } from 'modules/auth/api/types'

import {
  ChangePhoneNumberRequest,
  EditUserInfoRequest,
  FeedbackRequest,
  UserInfoResponse,
} from './types'

export const getUserInfo = () => axios.get<BaseResponse<UserInfoResponse>>('/v2/admin/user')

export const editUserInfo = (body: EditUserInfoRequest) =>
  axios.patch<MessageResponse>('/v2/admin/user', body)

export const uploadProfilePicture = (data: FormData) =>
  axios.post<BaseResponse<string>>('/v2/admin/user/upload_profile_picture', data, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  })

export const createFeedback = (body: FeedbackRequest) =>
  axios.post<MessageResponse>('/v2/feedback', body)

export const sendOTPChangePhone = (body: SendOTPBody) =>
  axios.post<MessageResponse>('/v2/admin/phone_number/send_sms_otp', body)

export const verifyOTPChangePhone = (body: VerifyOTPBody) =>
  axios.post<BaseResponse<VerifyOTPResponse>>('/v2/admin/phone_number/verify_sms_otp', body)

export const changePhoneNumber = (body: ChangePhoneNumberRequest) =>
  axios.patch<MessageResponse>('/v2/admin/phone_number', body)
