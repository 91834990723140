// ----------------------------------------------------------------------

function path(root: string, sublink: string) {
  return `${root}${sublink}`
}

export const ROOTS_PATH = "/"
const ROOTS_AUTH = '/auth'
const ROOTS_DASHBOARD = '/dashboard'
const ROOTS_SETTINGS = '/settings'

// ----------------------------------------------------------------------

export const PATH_AUTH = {
  root: ROOTS_AUTH,
  login: path(ROOTS_AUTH, '/login'),
  loginUnprotected: path(ROOTS_AUTH, '/login-unprotected'),
  register: path(ROOTS_AUTH, '/register'),
  registerUnprotected: path(ROOTS_AUTH, '/register-unprotected'),
  resetPassword: path(ROOTS_AUTH, '/reset-password'),
  verify: path(ROOTS_AUTH, '/verify'),
  settings: ROOTS_SETTINGS,
}

export const PATH_DASHBOARD = {
  root: ROOTS_DASHBOARD,
  general: {
    app: path(ROOTS_DASHBOARD, '/farms'),
    settings: path(ROOTS_DASHBOARD, '/settings'),
    profile: path(ROOTS_DASHBOARD, '/profile'),
    feedback: path(ROOTS_DASHBOARD, '/feedback'),
    changePhone: path(ROOTS_DASHBOARD, '/change-phone'),
    resetPassword: path(ROOTS_DASHBOARD, '/reset-password'),
  },
  admin: {
    root: path(ROOTS_DASHBOARD, '/admin'),
  },
  farms: {
    root: path(ROOTS_DASHBOARD, '/farms'),
  },
  employees: {
    root: path(ROOTS_DASHBOARD, '/employees'),
  },
  ponds: {
    root: path(ROOTS_DASHBOARD, '/ponds'),
  },
}
