import { ReactNode } from 'react'

import Box from '@mui/material/Box'

import { TLink } from 'components/@material-extend/MBreadcrumbs'
import HeaderBreadcrumbs from 'components/HeaderBreadcrumbs'
import Page from 'components/Page'

interface PageWrapperProps {
  title: string
  children: ReactNode
  links: TLink[]
  titleHead?: string
  action?: ReactNode
}

const PageWrapper = ({ children, titleHead = 'DoFarm', title, links, action }: PageWrapperProps) => {
  return (
    <Page title={titleHead}>
      <Box sx={{ px: { xs: 1, sm: 5 } }}>
        <HeaderBreadcrumbs heading={title} links={links} action={action} />
        <Box sx={{ py: 1 }}>{children}</Box>
      </Box>
    </Page>
  )
}

export default PageWrapper
